export const msalConfig = {
  auth: {
    authority: 'https://login.microsoftonline.com/d3240d57-3e67-425f-8b50-c7125b49e580',
    clientId: '29b725ff-3bc1-4a8a-b859-9cacecdbabe9',
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['https://storage.azure.com/user_impersonation'],
};
