import type { ChangeEvent } from 'react';

import type { StorageAccount } from '../../types/storageaccount.types';

interface StorageAccountSelectorProps {
  storageAccounts?: StorageAccount[];
  selectedStorageAccount?: string;
  onStorageAccountChange: (event: ChangeEvent<HTMLSelectElement>) => void;
}

export function StorageAccountSelector({
  storageAccounts,
  selectedStorageAccount,
  onStorageAccountChange,
}: StorageAccountSelectorProps) {
  return (
    <div className="flex flex-row items-center">
      <label htmlFor="storageAccount" className="mr-2">
        Storage Account:
      </label>
      <select
        name="storageAccount"
        id="storageAccount"
        onChange={onStorageAccountChange}
        value={selectedStorageAccount ?? 'default'}
        className="rounded-lg border py-1 text-sm"
      >
        <option disabled value="default" className="hidden">
          Select a storage account
        </option>
        {storageAccounts
          ? storageAccounts.map((sa) => (
              <option key={sa.name} value={sa.name}>
                {sa.niceName || sa.name}
              </option>
            ))
          : null}
      </select>
    </div>
  );
}
