import type { StorageAccount, Container } from '../types/storageaccount.types';

import fetchStorageAccountContainers from './fetchStorageAccountContainers';

export const storageAccountContainersQuery = (storageAccountName: string) => ({
  queryFn: (): Promise<Container[] | undefined> =>
    fetchStorageAccountContainers(storageAccountName),
  queryKey: ['storageAccountContainers', storageAccountName],
});

export const storageAccountsQuery = () => ({
  queryFn: (): Promise<StorageAccount[] | undefined> =>
    fetch('/api/storageAccounts').then((res) => res.json()),
  queryKey: ['storageAccounts'],
});
