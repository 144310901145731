import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import type { SearchQueryResult } from '../types/search.types';

interface SearchArgs {
  keyword: string;
  storageAccountName: string | undefined;
  containerName: string | undefined;
  skip: number;
  top: number;
}
export const searchIndexerAPI = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `https://holmesazaestoragesearch.search.windows.net/indexes/holmesazaestoragesearch-index/docs`,
    // baseUrl: 'https://holmesazaeuserarchivesearch.search.windows.net/',
    prepareHeaders: (headers) => {
      const apiKey = '1PGO32gSZopsDstn4U7NeCHXbAoBBRMUgYbSgVX1uvAzSeCtZjVA';
      headers.set('api-key', apiKey);
      headers.set('Content-Type', 'application/json');

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getSearchKeyword: builder.query<SearchQueryResult, SearchArgs>({
      query: ({ keyword, storageAccountName, containerName, skip, top }) =>
        `?api-version=2021-04-30-Preview&$count=true&searchFields=metadata_storage_name&search=${keyword}&$filter=search.ismatch('"${storageAccountName}" AND "${containerName}"', 'file_path', 'full', 'all')&$skip=${skip}&$top=${top}`,
    }),
  }),
  reducerPath: 'searchIndexerAPI',
});

export const { useGetSearchKeywordQuery } = searchIndexerAPI;

//https://holmesazaeuserarchivesearch.search.windows.net/indexes('azureblob-index')/$metadata#docs(*)
// Base query
// 'https://holmesazaeuserarchivesearch.search.windows.net/indexes/azureblob-index/docs?api-version=2021-04-30-Preview&$count=true',

// Can use useAppSelector(selectedStorageAccount) for filtering data in the query
// &$filter=storageAccount eq ${useAppSelector(selectedStorageAccount)} and container eq ${useAppSelector(selectedStorageAccount)}

// specific index search request
// ${storageAccountName}-${containerName}-index/docs?api-version=2021-04-30-Preview&$count=true&searchFields=metadata_storage_name&search=${keyword}&$skip=${skip}&$top=${top}

// entire storage account and containers request
// holmesazaestoragesearch-index/docs?api-version=2021-04-30-Preview&$count=true&searchFields=metadata_storage_name&search=${keyword}&$skip=${skip}&$top=${top}
