import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { msalInstance, acquireAccessToken } from '../../services/msal';
import { useLazyGetSasTokenQuery } from '../../services/sasTokenAPI';

type PreviewPaneVideoProps = {
  src: string;
  type: string;
};

export default function PreviewPaneVideo({ src }: PreviewPaneVideoProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [accessToken, setAccessToken] = useState('');
  const [sasToken, setSasToken] = useState('');
  const { storageAccountName, containerName } = useParams();

  // Use a lazy query so that the SAS token is not requested until the access token is available.
  // This is kinda necessary as you can't use a hook inside a useEffect. This works around that.
  const [getSasToken] = useLazyGetSasTokenQuery();

  useEffect(() => {
    const getAccessToken = async () => {
      const tokenResponse = await acquireAccessToken(msalInstance);
      return tokenResponse;
    };
    getAccessToken().then((token) => {
      setAccessToken(token);
    });
  }, []);

  useEffect(() => {
    if (accessToken && containerName && storageAccountName) {
      getSasToken({
        accessToken: accessToken,
        containerName: containerName,
        storageAccountName: storageAccountName,
      }).then((result) => {
        if (result.data) {
          setSasToken(result.data.sasToken);
        }
      });
    }
  }, [accessToken, containerName, storageAccountName]);

  // const videoStream = () => {
  //   if (!isStreaming && sasToken) {
  //     fetch(`${src}?${sasToken?.sasToken}`, {
  //       headers: { Range: 'bytes=0-' },
  //     }).then(async (response) => {
  //       if (response.status === 206) {
  //         // if response status is 206 - Partial Content, set the video source
  //         videoRef.current!.src = `${src}?${sasToken?.sasToken}`;
  //       }
  //     });
  //     // .catch((error) => {
  //     //   console.error('Error loading video:', error);
  //     // });
  //   }
  // };

  // useEffect(() => {
  //   if (videoRef.current && sasToken) {
  //     const video = videoRef.current;
  //     video.addEventListener('canplay', () => {
  //       video.play();
  //     });
  //   }
  // }, [videoRef.current]);

  return (
    <video width="%50" height="%50" controls ref={videoRef} autoPlay muted>
      {/* Most browsers will only allow autoPlay if it's muted */}
      {sasToken && <source src={`${src}?${sasToken}`} type={'video/mp4'} />}
      {/* track tag is required by lint */}
      <track src="" kind="captions" srcLang="en"></track>
    </video>
  );
}
