import type { ChangeEvent } from 'react';

import type { Container } from '../../types/storageaccount.types';

interface StorageAccountContainerProps {
  storageContainers?: Container[];
  selectedContainer?: string;
  disabled?: boolean;
  onContainerChange: (event: ChangeEvent<HTMLSelectElement>) => void;
}

export function ContainerSelector({
  storageContainers,
  selectedContainer,
  disabled,
  onContainerChange,
}: StorageAccountContainerProps) {
  return (
    <div className="ml-5 flex flex-row items-center">
      <label htmlFor="container" className="mr-2">
        Container:
      </label>
      <select
        name="container"
        id="container"
        onChange={(e) => onContainerChange(e)}
        value={selectedContainer ?? 'default'}
        className="w-full rounded-lg border py-1 text-sm"
        disabled={disabled}
      >
        <option disabled value="default" className="hidden">
          {!disabled ? 'Select a container' : 'Select a storage account first'}
        </option>
        {storageContainers
          ? storageContainers.map((sa) => (
              <option key={sa.name} value={sa.name}>
                {sa.name}
              </option>
            ))
          : null}
      </select>
    </div>
  );
}
