import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { blobSelectorByPrefix, getBlobs } from '../../services/blobSlice';
import { decodeBlobPath } from '../../utils/utils';
import { Spinner } from '../spinner/Spinner';

import BackButton from './BackButton';
import Table from './Table';

export default function BlobList() {
  const dispatch = useAppDispatch();
  const { storageAccountName, containerName, '*': blobPath } = useParams();

  const { prefix } = decodeBlobPath(blobPath ?? '');

  const loading = useAppSelector((state) => state.blob.loading);

  useEffect(() => {
    if (containerName != null && storageAccountName != null) {
      dispatch(
        getBlobs({
          container: containerName,
          prefix: prefix,
          storageAccount: storageAccountName,
        })
      );
    }
  }, [containerName, storageAccountName, prefix]);

  const displayBlobs = useAppSelector((state) =>
    blobSelectorByPrefix(state, prefix, storageAccountName || '', containerName || '')
  );

  return (
    <div className="flex grow flex-col overflow-auto px-10 py-2">
      <div className="flex flex-row items-start items-center">
        <BackButton disabled={prefix === '' ? true : false} />
        <span className="text ml-4 rounded border p-1">
          {containerName}
          {`/${prefix}`}
        </span>
        {loading === 'pending' ? (
          <div className="ml-4">
            <Spinner />
          </div>
        ) : null}
      </div>
      <div className="flex grow flex-col overflow-auto py-2">
        <Table data={displayBlobs} />
      </div>
    </div>
  );
}
