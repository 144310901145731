import { BlobServiceClient } from '@azure/storage-blob';

import customTokenCredential from '../services/CustomTokenProvider';
import { msalInstance, acquireAccessToken } from '../services/msal';
import type { Container } from '../types/storageaccount.types';

export default async function fetchStorageAccountContainers(storageAccount: string) {
  if (!storageAccount) {
    return [];
  }
  try {
    const token = await acquireAccessToken(msalInstance);
    const tokenCredential = new customTokenCredential(token);
    const blobClient = new BlobServiceClient(
      `https://${storageAccount}.blob.core.windows.net`,
      tokenCredential
    );
    const containers: Container[] = [];
    const iter = blobClient.listContainers();
    let containerItem = await iter.next();
    while (!containerItem.done) {
      containers.push({
        id: containerItem.value.properties.etag,
        name: containerItem.value.name,
        storageAccount: storageAccount,
      });
      containerItem = await iter.next();
    }
    return containers;
  } catch (err: any) {
    return err.response.data;
  }
}
