import { BlobServiceClient } from '@azure/storage-blob';
import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';

import type { RootState } from '../store';
import type { Container } from '../types/storageaccount.types';

import customTokenCredential from './CustomTokenProvider';
import { msalInstance, acquireAccessToken } from './msal';

const containerAdapter = createEntityAdapter<Container>();

export const getContainers = createAsyncThunk<Container[], string, { rejectValue: any }>(
  'container/getContainers',
  async (storageAccount: string, { rejectWithValue }) => {
    try {
      const token = await acquireAccessToken(msalInstance);
      const tokenCredential = new customTokenCredential(token);
      const blobClient = new BlobServiceClient(
        `https://${storageAccount}.blob.core.windows.net`,
        tokenCredential
      );
      const containers: Container[] = [];
      const iter = blobClient.listContainers();
      let containerItem = await iter.next();
      while (!containerItem.done) {
        containers.push({
          id: containerItem.value.properties.etag,
          name: containerItem.value.name,
          storageAccount: storageAccount,
        });
        containerItem = await iter.next();
      }
      return containers;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

const containerSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getContainers.fulfilled, (state, { payload }) => {
      containerAdapter.upsertMany(state, payload);
    });
  },
  initialState: containerAdapter.getInitialState(),
  name: 'container',
  reducers: {
    setContainers(state, { payload }) {
      containerAdapter.upsertMany(state, payload);
    },
  },
});

export default containerSlice.reducer;

export const containerSelectors = containerAdapter.getSelectors(
  (state: RootState) => state.container
);

export const { setContainers } = containerSlice.actions;

// Selector to get all containers for a storage account
export const containerSelectorByStorageAccount = createSelector(
  containerSelectors.selectAll,
  (state: RootState, storageAccount: string) => storageAccount,
  (containers, storageAccount) =>
    containers.filter((container) => container.storageAccount === storageAccount)
);
