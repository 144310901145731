import { queryClient } from '../queries/queryClient';

import Browse, { loader as browseLoader } from './Browse';
import { ProtectedRoute } from './ProtectedRoute';
import { Root } from './RootLayout';
import { UnauthenticatedHome } from './UnauthenticatedHome';

/* eslint-disable sort-keys-fix/sort-keys-fix */
const routes = [
  {
    path: '/',
    element: <Root />,
    children: [
      {
        index: true,
        element: <UnauthenticatedHome />,
      },
      {
        path: 'browse/:storageAccountName?/:containerName?/*',
        id: 'browse',
        loader: browseLoader(queryClient),
        element: (
          <ProtectedRoute>
            <Browse />
          </ProtectedRoute>
        ),
      },
    ],
  },
];
/* eslint-enable sort-keys-fix/sort-keys-fix */

export default routes;
