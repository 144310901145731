import { createSlice, createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';

import type { RootState } from '../store';
import type { StorageAccount } from '../types/storageaccount.types';

import { getBlobs } from './blobSlice';
import { getContainers, setContainers } from './containerSlice';

export const changeStorageAccount = createAsyncThunk<void, StorageAccount, { state: RootState }>(
  'hab/changeStorageAccount',
  async (storageAccount: StorageAccount, thunkApi) => {
    // Set the new storage account, then get all containers in that account
    await thunkApi.dispatch(habSlice.actions.clearContainer());
    await thunkApi.dispatch(habSlice.actions.clearBlobId());
    await thunkApi.dispatch(habSlice.actions.clearPrefix());
    await thunkApi.dispatch(habSlice.actions.setStorageAccount(storageAccount.name));
    // If the HAB API did not return a list of containers, get them from the storage account
    //  Otherwise use the list returned by the HAB API
    if (storageAccount.containers.length === 0) {
      const containerThunk = await thunkApi.dispatch(getContainers(storageAccount.name));
      const containers = unwrapResult(containerThunk);
      // If only one container, select it for the user automatically
      if (containers.length === 1) {
        await thunkApi.dispatch(changeContainer(containers[0].name));
      }
    } else {
      await thunkApi.dispatch(setContainers(storageAccount.containers));
      // If only one container, select it for the user automatically
      if (storageAccount.containers.length === 1) {
        await thunkApi.dispatch(changeContainer(storageAccount.containers[0].name));
      }
    }
  }
);

export const changeContainer = createAsyncThunk<void, string, { state: RootState }>(
  'hab/changeContainer',
  async (container: string, thunkApi) => {
    // Set the new container
    await thunkApi.dispatch(habSlice.actions.clearBlobId());
    await thunkApi.dispatch(habSlice.actions.clearPrefix());
    await thunkApi.dispatch(habSlice.actions.setContainer(container));
    // Get all blobs in the container
    const storageAccount = thunkApi.getState().hab.selectedStorageAccount;
    if (storageAccount) {
      await thunkApi.dispatch(getBlobs({ container, prefix: '', storageAccount }));
    }
  }
);

type SliceState = {
  selectedStorageAccount: string | null;
  selectedContainer: string | null;
  selectedBlobId: string;
  currentPrefix: string;
};

const initialState: SliceState = {
  currentPrefix: '',
  selectedBlobId: '',
  selectedContainer: null,
  selectedStorageAccount: null,
};

const habSlice = createSlice({
  initialState,
  name: 'hab',
  reducers: {
    clearBlobId(state) {
      state.selectedBlobId = '';
    },
    clearContainer(state) {
      state.selectedContainer = null;
    },
    clearPrefix(state) {
      state.currentPrefix = '';
    },
    clearStorageAccount(state) {
      state.selectedStorageAccount = null;
    },
    setBlobId(state, { payload }) {
      state.selectedBlobId = payload;
    },
    setContainer(state, { payload }) {
      state.selectedContainer = payload;
    },
    setPrefix(state, { payload }) {
      state.currentPrefix = payload;
    },
    setStorageAccount(state, { payload }) {
      state.selectedStorageAccount = payload;
    },
  },
});

export default habSlice.reducer;

// Export actions
export const { clearPrefix, clearBlobId, setBlobId, setPrefix, setStorageAccount, setContainer } =
  habSlice.actions;

// Export selectors
export const selectedContainerSelector = (state: RootState) => state.hab.selectedContainer;
export const selectedStorageAccountSelector = (state: RootState) =>
  state.hab.selectedStorageAccount;
export const currentPrefixSelector = (state: RootState) => state.hab.currentPrefix;
export const selectedBlobIdSelector = (state: RootState) => state.hab.selectedBlobId;
