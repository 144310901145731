import MsgReader from '@kenjiuno/msgreader';

type PreviewPaneEmailProps = {
  buffer: ArrayBuffer;
  filename: string;
};

export default function PreviewPaneEmail({ buffer, filename }: PreviewPaneEmailProps) {
  const msgReader = new MsgReader(buffer);
  const fileData = msgReader.getFileData();
  const data = JSON.parse(JSON.stringify(fileData));
  let recipients = '';

  function getEmailBody() {
    // If email has error parsing the body of the email
    if (typeof data.body != 'string') {
      return (
        <div className="underline font-bold">
          **Parsing error on the email body, please download the email to preview.**
        </div>
      );
    }
    return data.body;
  }

  function hasNumber(myString: string) {
    return /\d/.test(myString);
  }

  // Not all emails have headers, particulary emails filed to NF from the Sent folder in Outlook
  function getDate() {
    // Manually parse header for Date
    if (data.headers) {
      const arr = data.headers.split(' ');
      let parsedDate = '';
      for (let i = 0; i < 5; i++) {
        parsedDate += arr[10 + i] + ' ';
      }
      return new Date(parsedDate).toLocaleString();
    } else {
      // if no headers found, checks if the first 16 characters of filename for date
      if (hasNumber(filename.slice(0, 15)) && filename.slice(0, 15).includes('-')) {
        const year = Number(filename.slice(0, 4));
        const day = Number(filename.slice(5, 7));
        const month = Number(filename.slice(8, 10));
        const hours = Number(filename.slice(11, 13));
        const minutes = Number(filename.slice(13, 15));
        return new Date(year, month - 1, day, hours, minutes).toLocaleString();
      }
    }
    return 'Unknown';
  }

  // handles multiple recipients
  if (buffer) {
    if (data.recipients) {
      for (let i = 0; i <= data.recipients.length - 1; i++) {
        recipients += data.recipients[i].name;
        if (i < data.recipients.length - 1) {
          recipients += ', ';
        } else if (i === data.recipients.length) {
          break;
        }
      }
    }

    return (
      <div>
        <div className="text-left">
          <div className="field-block">
            <div className="field-label">From: {data.senderName}</div>
          </div>
          <div className="field-block">
            <div className="field-label">To: {recipients}</div>
          </div>
          <div className="field-block">
            <div className="field-label">Date: {getDate()}</div>
          </div>
          <div className="field-block">
            <div className="field-label">Subject: {data.subject}</div>
          </div>
          <br></br>
          <div className="field-block break-words whitespace-pre-wrap">
            <div>{getEmailBody()}</div>
          </div>
        </div>
      </div>
    );
  }

  return <div></div>;
}
