import { useState } from 'react';

import { useAppDispatch } from '../../hooks';
import { getBlobObject } from '../../services/downloadSlice';
import { Spinner } from '../spinner/Spinner';

interface DownloadButtonProps {
  fileName: string;
  blobUrl: string;
}

export function DownloadButton({ blobUrl, fileName }: DownloadButtonProps) {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const handleDownload = async () => {
    setLoading(true);
    const blob = await dispatch(getBlobObject(blobUrl)).unwrap();
    if (blob instanceof Blob) {
      const url = URL.createObjectURL(blob);
      const alink = document.createElement('a');
      alink.href = url;
      alink.download = fileName;
      alink.click();
      URL.revokeObjectURL(url);
    }
    setLoading(false);
  };

  return (
    <>
      {loading ? (
        <div className="rounded border px-8 py-1">
          <Spinner />
        </div>
      ) : (
        <button className="rounded border p-1" onClick={handleDownload}>
          Download
        </button>
      )}
    </>
  );
}
