import { useNavigate } from 'react-router-dom';

import type { SearchResultItem } from '../../types/search.types';
import { convertDateString, humanFileSize } from '../../utils/utils';

type IndexedSearchProps = {
  data: SearchResultItem[];
};

export function SearchTable({ data: results }: IndexedSearchProps) {
  const navigate = useNavigate();

  // When search result is clicked, navigates user to path and previews file
  const handleSearchedResultClick = (filePath: string) => {
    const storageAccount = filePath.split('/')[2].split('.')[0];
    const container = filePath.split('/')[3];
    const path = filePath.split('/').slice(4).join('/');
    document.getElementById('search-modal')?.click();
    navigate(`/browse/${storageAccount}/${container}/${path}`);
  };

  return (
    <div className="justify-center">
      <table className="table-normal table">
        <thead className="dark:text-slate-300 text-bold text-base">
          <tr className=" w-full border-b-slate-400 dark:border-b-white">
            <th>Name</th>
            <th>Size</th>
            <th>Modified Date</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          {results.map((item: SearchResultItem) => {
            const fileExtension = item.metadata_storage_name.split('.');
            if (results) {
              return (
                <tr key={item.id} onClick={() => handleSearchedResultClick(item.file_path)}>
                  {/* Make size and last modfied date human readable */}
                  <th>{item.metadata_storage_name}</th>
                  <th>{humanFileSize(item.metadata_storage_size)}</th>
                  <th>{convertDateString(item.metadata_storage_last_modified)}</th>
                  <th>{fileExtension[fileExtension.length - 1].toUpperCase()}</th>
                </tr>
              );
            }
          })}
        </tbody>
      </table>
    </div>
  );
}
