import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import type { StorageAccount } from '../types/storageaccount.types';

export const habApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: '/api' }),
  endpoints: (builder) => ({
    getStorageAccounts: builder.query<StorageAccount[], void>({
      query: () => 'storageAccounts',
    }),
  }),
  reducerPath: 'habApi',
});

export const { useGetStorageAccountsQuery } = habApi;
