import { useIsAuthenticated } from '@azure/msal-react';
import HABLogo from '../../img/hab.png';
import { SignInButton } from '../auth/SignInButton';
import { IndexedSearch } from '../browse/IndexedSearch';

export function Header() {
  const isAuthenticated = useIsAuthenticated();

  return (
    <header className="h-15 flex-none">
      <nav className="border-gray-200 bg-white px-6 py-2.5">
        <div className="mx-auto flex max-w-screen-xl flex-wrap items-center justify-between">
          <button className="flex items-center">
            <span className="sr-only">HAB</span>
            <img className="mr-3 h-6 sm:h-9" src={HABLogo} alt="" />
            <div className="flex flex-col">
              <span className="mt-1 whitespace-nowrap text-left text-xl font-semibold">HAB</span>
              <span className="-mt-1 text-left text-[10px]">{__COMMIT_HASH__}</span>
            </div>
          </button>
          {isAuthenticated ? (
            <div className="order-1 flex w-auto justify-between">
              <IndexedSearch />
            </div>
          ) : (
            <div></div>
          )}
          <div className="order-2 flex items-center">
            <SignInButton />
          </div>
        </div>
      </nav>
    </header>
  );
}
