import { BlobClient } from '@azure/storage-blob';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import customTokenCredential from './CustomTokenProvider';
import { msalInstance, acquireAccessToken } from './msal';

export const getBlobObject = createAsyncThunk(
  'download/getBlobContent',
  async (blobUrl: string, { rejectWithValue }) => {
    try {
      const token = await acquireAccessToken(msalInstance);
      const tokenCredential = new customTokenCredential(token);
      const blobClient = new BlobClient(blobUrl, tokenCredential);
      const blobContent = await blobClient.download();
      const blobBody = await blobContent.blobBody;
      return blobBody;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState = {
  cachedUrls: [],
  loading: 'idle',
};

const downloadSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(getBlobObject.fulfilled, (state) => {
        state.loading = 'idle';
      })
      .addCase(getBlobObject.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(getBlobObject.rejected, (state) => {
        state.loading = 'idle';
      });
  },
  initialState,
  name: 'download',
  reducers: {},
});

export default downloadSlice.reducer;
