import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '../../hooks';
import { blobSelectorByPath } from '../../services/blobSlice';
import { getBlobObject } from '../../services/downloadSlice';
import { Spinner } from '../spinner/Spinner';

import PreviewPaneDoc from './PreviewPaneDoc';
import PreviewPaneEmail from './PreviewPaneEmail';
import PreviewPaneImg from './PreviewPaneImg';
import PreviewPanePDF from './PreviewPanePDF';
import PreviewPaneVideo from './PreviewPaneVideo';

export default function PreviewPane() {
  const [blobContentUrl, setBlobContentUrl] = useState(null);
  const { storageAccountName, containerName, '*': blobPath } = useParams();
  const currentBlob = useAppSelector((state) =>
    blobSelectorByPath(state, blobPath || '', storageAccountName || '', containerName || '')
  );
  const [loading, setLoading] = useState(false);
  const [PreviewBlocked, setPreviewBlocked] = useState(true);
  const [fileContent, setFileContent] = useState(null);
  const dispatch = useAppDispatch();

  const previewableTypes = [
    'image/png',
    'image/jpeg',
    'image/gif',
    'image/svg+xml',
    'text/plain',
    'text/html',
    'application/pdf',
    'application/octet-stream',
    'application/zip',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'video/mp4',
    'video/x-m4v',
    'video/quicktime',
  ];

  //When Preview Anyway button is clicked, state of PreviewBlocked is set to false, when false it will preview the file
  const handlePreviewClick = () => {
    setPreviewBlocked(false);
  };

  //This useEffect fetches for the currently selected item's info and loading state, all depending if PreviewBlocked is false, when state is false it will fetch for the file and preview it
  useEffect(() => {
    if (PreviewBlocked == false) {
      const blobUrl = currentBlob
        ? `https://${currentBlob.storageAccount}.blob.core.windows.net/${currentBlob.container}/${currentBlob.name}`
        : null;

      const handleFetchBlobContent = async (blobUrl) => {
        setLoading(true);
        if (currentBlob.properties.contentType.includes('video')) {
          const tokenize = blobUrl.split('/');
          for (let i = 3; i < tokenize.length; i++) {
            tokenize[i] = encodeURIComponent(tokenize[i]);
          }
          const modifiedUrl = tokenize.join('/');
          setBlobContentUrl(modifiedUrl);
          setLoading(false);
        } else {
          const content = await dispatch(getBlobObject(blobUrl)).unwrap();
          setFileContent(await new Response(content).arrayBuffer());
          const url = URL.createObjectURL(content);
          setBlobContentUrl(url);
          setLoading(false);
        }
      };

      if (currentBlob && previewableTypes.includes(currentBlob.properties.contentType)) {
        // need to get blobcontent here and setBlobContentUrl()
        setBlobContentUrl(null);
        handleFetchBlobContent(blobUrl, setBlobContentUrl);
      } else {
        setBlobContentUrl(null);
        setFileContent(null);
      }
    }
  }, [currentBlob, setBlobContentUrl, PreviewBlocked]);

  //This useEffect checks if current item selected is less than 5MB, if so it will preview the file, otherwise it won't
  useEffect(() => {
    if (currentBlob) {
      if (currentBlob.properties.contentLength <= 5000000) {
        setPreviewBlocked(false);
      } else {
        setPreviewBlocked(true);
      }
    }
  }, [currentBlob]);

  if (currentBlob) {
    // Don't display preview if the content type is not supported
    if (!previewableTypes.includes(currentBlob.properties.contentType)) {
      return <div>Preview not available for {currentBlob.properties.contentType}</div>;
    }

    //If preview is blocked, it'll let user know and provide button to preview
    if (PreviewBlocked) {
      return (
        <div>
          This file is larger than 5MB, click below to preview anyway.
          <br></br>
          <br></br>
          <button className="rounded border p-1" onClick={handlePreviewClick}>
            Preview
          </button>
        </div>
      );
    }

    if (loading) {
      return <Spinner />;
    }

    if (blobContentUrl) {
      switch (currentBlob.properties.contentType) {
        case 'image/png':
        case 'image/jpeg':
        case 'image/gif':
        case 'image/svg+xml':
          return <PreviewPaneImg src={blobContentUrl} alt={currentBlob.name} />;

        case 'text/plain':
        case 'text/html':
          return <iframe src={blobContentUrl} title={currentBlob.name} height="100%" />;

        case 'application/pdf':
          return <PreviewPanePDF src={blobContentUrl} />;

        case 'application/octet-stream':
          if (currentBlob.relativeName.includes('.msg')) {
            return <PreviewPaneEmail buffer={fileContent} filename={currentBlob.relativeName} />;
          }
          break;

        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'application/zip':
          //includes both .doc and .docx files
          if (currentBlob.relativeName.includes('.doc')) {
            return <PreviewPaneDoc buffer={fileContent} />;
          }
          break;

        case 'video/x-m4v':
        case 'video/quicktime':
        case 'video/mp4':
          return <PreviewPaneVideo src={blobContentUrl} />;

        default:
          return <div>Preview not available.</div>;
      }
    }

    return <div>Preview not available for {currentBlob.properties.contentType}</div>;
  }
  //If preview is blocked, it'll let user know and provide button to preview

  return <div></div>;
}
