import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { loginRequest } from '../authConfig';

const initialState = {
  loading: 'idle',
};

export const login = createAsyncThunk('auth/login', async (instance, { rejectWithValue }) => {
  try {
    await instance.loginPopup(loginRequest);
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

export const logout = createAsyncThunk('auth/logout', async (instance, { rejectWithValue }) => {
  try {
    await instance.logoutPopup();
  } catch (err) {
    return rejectWithValue(err.response.data);
  }
});

const authSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(login.fulfilled, (state) => {
      state.loading = 'idle';
    });
    builder.addCase(logout.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.loading = 'idle';
    });
  },
  initialState,
  name: 'auth',
  reducers: {},
});

export default authSlice.reducer;
