import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { useParams } from 'react-router-dom';

import { storageAccountsQuery, storageAccountContainersQuery } from '../../queries/storageQueries';
import { useGetSearchKeywordQuery } from '../../services/searchIndexerAPI';
import { SearchTable } from '../containers/SearchTable';

import { ContainerSelector } from './ContainerSelector';
import { StorageAccountSelector } from './StorageAccountSelector';

export function IndexedSearch() {
  const { storageAccountName, containerName } = useParams();

  const [searchWord, setSearchWord] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedStorageAccount, setSelectedStorageAccount] = useState(storageAccountName);
  const [selectedContainer, setSelectedContainer] = useState(containerName);

  const { data: storageAccounts } = useQuery(storageAccountsQuery());
  const { data: storageAccountContainers } = useQuery(
    storageAccountContainersQuery(selectedStorageAccount || '')
  );

  const searchDisabled = !selectedStorageAccount || !selectedContainer;

  // Number of results returned in each page
  const pageSize = 20;
  // Skip the number of results returned in previous pages
  const skip = currentPage * pageSize;

  const { data: searchResults } = useGetSearchKeywordQuery(
    {
      keyword: searchWord,
      storageAccountName: selectedStorageAccount,
      containerName: selectedContainer,
      skip: skip,
      top: pageSize,
    },
    { skip: searchWord === '' } // Don't send request if searchWord is empty
  );

  // Default to no results if searchResults is undefined
  const resultCount = searchResults?.['@odata.count'] ?? 0;
  const results = searchResults?.value ?? [];

  const totalPages = Math.ceil(resultCount / pageSize);

  const previousPageAvailable = currentPage > 0;
  const nextPageAvailable = currentPage < totalPages - 1;

  const handlePreviousClick = () => {
    // Go to previous page if current page is not the first page
    if (previousPageAvailable) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    // Go to next page if current page is not the last page
    if (nextPageAvailable) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className="ml-5 flex w-full flex-row items-center">
      {/* Modal Button */}
      <label
        htmlFor="search-modal"
        className="flex flex-nowrap space-x-2 rounded-full bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
          />
        </svg>
        <p>Search</p>
      </label>
      <input type="checkbox" id="search-modal" className="modal-toggle" />

      {/* Modal Window */}
      <div className="modal w-full items-center justify-between">
        <div className="modal-box fixed h-full w-full max-w-6xl">
          <div className="modal-action mb-10">
            <label
              htmlFor="search-modal"
              className=" absolute right-4 top-4 rounded-lg bg-red-900 px-4 py-2 font-bold text-white hover:bg-red-950"
            >
              <p>X</p>
            </label>
          </div>
          <div id="search-bar" className="mb-5 text-center">
            <div
              id="account-selectors"
              className="align-center mb-5 flex flex-nowrap justify-between"
            >
              <StorageAccountSelector
                storageAccounts={storageAccounts}
                selectedStorageAccount={selectedStorageAccount}
                onStorageAccountChange={(e) => setSelectedStorageAccount(e.target.value)}
              />
              <ContainerSelector
                storageContainers={storageAccountContainers}
                selectedContainer={selectedContainer}
                onContainerChange={(e) => setSelectedContainer(e.target.value)}
                disabled={!selectedStorageAccount}
              />
            </div>
            <DebounceInput
              className={
                searchDisabled
                  ? 'mt-4 w-2/3 rounded-xl bg-gray-300 text-center'
                  : 'mt-4 w-2/3 rounded-xl text-center'
              }
              placeholder="Search"
              minLength={1}
              debounceTimeout={1500}
              onChange={(e) => setSearchWord(e.target.value)}
              disabled={searchDisabled}
            />
          </div>

          <div id="search-table" className="h-min-1/12 h-max-2/12 w-full">
            <SearchTable data={results} />
          </div>
          {resultCount > 0 ? (
            <div className="btn-group grid grid-cols-2">
              <button
                className="btn-outline btn"
                disabled={!previousPageAvailable}
                onClick={() => handlePreviousClick()}
              >
                Previous page
              </button>
              <button
                className="btn-outline btn"
                disabled={!nextPageAvailable}
                onClick={() => handleNextClick()}
              >
                Next
              </button>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
      {/* Add a dropdown menu or find a way to clear and then populate detail pane */}
    </div>
  );
}
