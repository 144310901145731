import { Outlet } from 'react-router-dom';

import { Header } from '../features/header/Header';

export function Root() {
  return (
    <main className="flex flex-col h-screen">
      <Header />
      <Outlet />
    </main>
  );
}
