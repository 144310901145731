import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import authReducer from './services/authSlice';
import blobReducer from './services/blobSlice';
import containerReducer from './services/containerSlice';
import downloadReducer from './services/downloadSlice';
import { habApi } from './services/habAPI';
import habReducer from './services/habSlice';
import { sasAPI } from './services/sasTokenAPI';
import { searchIndexerAPI } from './services/searchIndexerAPI';

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      habApi.middleware,
      searchIndexerAPI.middleware,
      sasAPI.middleware
    ),
  reducer: {
    auth: authReducer,
    blob: blobReducer,
    container: containerReducer,
    download: downloadReducer,
    [habApi.reducerPath]: habApi.reducer,
    hab: habReducer,
    [searchIndexerAPI.reducerPath]: searchIndexerAPI.reducer,
    [sasAPI.reducerPath]: sasAPI.reducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
