import { renderAsync } from 'docx-preview';

export default function PreviewPaneDoc({ buffer }) {
  const htmlStyle = {
    transform: 'scale(1, 1)',
  };

  function renderHTML() {
    //wait 1 ns before executing, this function executes faster than div is loaded
    setTimeout(function () {
      if (buffer) {
        const container = document.getElementById('word-file');
        renderAsync(buffer, container).catch(function (data) {
          if (container) {
            container.style.transform = 'initial';
            container.innerHTML =
              '<b><u>**File cannot be parsed, please download the file to preview.**</u></b>';
          }
        });
      }
    }, 1);
  }

  return (
    <div className="whitespace-pre-wrap">
      <div style={htmlStyle} id="word-file" onLoad={renderHTML()}></div>
    </div>
  );
}
