import { useParams } from 'react-router-dom';

import { useAppSelector } from '../../hooks';
import { blobSelectorByPath } from '../../services/blobSlice';
import { humanFileSize, getFileType } from '../../utils/utils';

import { DownloadButton } from './DownloadButton';

export default function DetailPane() {
  const { storageAccountName, containerName, '*': blobPath } = useParams();

  // select blob that matches the current storage account, container, and blobPath
  const currentBlob = useAppSelector((state) =>
    blobSelectorByPath(state, blobPath || '', storageAccountName || '', containerName || '')
  );

  if (currentBlob && currentBlob.properties) {
    const blobUrl = `https://${currentBlob.storageAccount}.blob.core.windows.net/${currentBlob.container}/${currentBlob.name}`;

    const nameArray = currentBlob.name.split('/');
    const fileName = nameArray[nameArray.length - 1];
    return (
      <div className="h-1/6 border-b p-4">
        <span className="text-lg font-bold">Selected File</span>
        <div className="flex flex-row pt-4">
          <div className="min-w-0 grow text-left">
            <div className="overflow-hidden text-ellipsis whitespace-nowrap">Name: {fileName}</div>
            <div>Size: {humanFileSize(currentBlob.properties.contentLength)}</div>
            <div>File Type: {String(getFileType(fileName))}</div>
          </div>
          <div className="shrink-0 pl-4">
            <DownloadButton blobUrl={blobUrl} fileName={fileName} />
          </div>
        </div>
      </div>
    );
  }
  return <div className="h-1/6 border-b p-4" />;
}
