import { resolveFileType } from 'friendly-mimes';

// https://github.com/bryc/code/blob/master/jshash/experimental/cyrb53.js
/*
    cyrb53 (c) 2018 bryc (github.com/bryc)
    A fast and simple hash function with decent collision resistance.
    Largely inspired by MurmurHash2/3, but with a focus on speed/simplicity.
    Public domain. Attribution appreciated.
*/
export function cyrb53(str: string, seed = 0) {
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }

  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);

  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
}

// https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(bytes = 0, si = true, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
}

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

// Written by Kevin Chan :')
export function convertDateString(date: string) {
  if (date != null || date != undefined) {
    const convertedDate = new Date(date);
    return convertedDate.toLocaleString();
  }
}

//utilizes the friendly-mimes module, needs a try catch in case some extensions are not found in its library
export function getFileType(filename: string) {
  const fileExtension = filename.split('.');
  const type = fileExtension[fileExtension.length - 1];
  let mime = '';
  try {
    mime = String(resolveFileType(`.${type}`).name);
  } catch (e) {
    // console.log(e);
  }
  return mime;
}

//pass in filename and string token with '.' and get last array index
//Modified Date and Content Length do not exists for folder directories
export function grabFileExtension(filename: string, lastModified: string, contentLength: string) {
  if (lastModified != 'undefined' && contentLength != 'undefined') {
    const fileExtension = filename.split('.');
    return fileExtension[fileExtension.length - 1];
  }
}

// splits a blob path into a prefix and filename
export function decodeBlobPath(blobPath: string) {
  // Splits blob path into prefix and filename
  // For now, the filename is the last element of the path, if that
  // element has an extension
  const pathElements = blobPath.split('/');

  if (blobPath.endsWith('/')) {
    // prefix must end in a slash
    const prefix = blobPath.endsWith('/') ? blobPath : blobPath + '/';
    return { filename: '', prefix: prefix };
  }

  const filename = pathElements[pathElements.length - 1];
  const pathPrefix = pathElements.slice(0, pathElements.length - 1).join('/');
  // prefix must end with a slash but not start with one (eg if the prefix is the root directory, it should be '')
  const prefix = pathPrefix.length > 0 ? pathPrefix + '/' : '';
  return { filename, prefix };
}
