import { PublicClientApplication } from '@azure/msal-browser';

import { msalConfig, loginRequest } from '../authConfig';

export const msalInstance = new PublicClientApplication(msalConfig);

export const acquireAccessToken = async (msalInstance) => {
  const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    /*
     * User is not signed in. Throw error or wait for user to login.
     * Do not attempt to log a user in outside of the context of MsalProvider
     */
  }
  const request = {
    account: activeAccount || accounts[0],
    scope: loginRequest.scopes,
  };

  const authResult = await msalInstance.acquireTokenSilent(request);

  return authResult.accessToken;
};
