import { ContainerClient } from '@azure/storage-blob';
import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';

import type { RootState } from '../store';
import type { Blob, BlobAttributes } from '../types/storageaccount.types';
import { cyrb53 } from '../utils/utils';

import customTokenCredential from './CustomTokenProvider';
import { msalInstance, acquireAccessToken } from './msal';

const blobAdapter = createEntityAdapter<Blob>();

const getRelativeName = (name: string) => {
  const parts = name.split('/');
  return parts[parts.length - 1] ? parts[parts.length - 1] : parts[parts.length - 2];
};

const getUrlSafePath = (path: string) => {
  const parts = path.split('/');
  return parts.map((part) => encodeURIComponent(part)).join('/');
};

export const getBlobs = createAsyncThunk<Blob[], BlobAttributes, { rejectValue: any }>(
  'blob/getBlobs',
  async ({ storageAccount, container, prefix }: BlobAttributes, { rejectWithValue }) => {
    try {
      const token = await acquireAccessToken(msalInstance);
      const tokenCredential = new customTokenCredential(token);
      const containerClient = new ContainerClient(
        `https://${storageAccount}.blob.core.windows.net/${container}`,
        tokenCredential
      );

      const iter = containerClient.listBlobsByHierarchy('/', { includeMetadata: true, prefix });
      let blobItem = await iter.next();
      const blobs: Blob[] = [];
      while (!blobItem.done) {
        const item = blobItem.value;
        if (item.kind === 'blob') {
          const properties = {
            contentLength: item.properties.contentLength || 0,
            contentType: item.properties.contentType || '',
            createdOn: item.properties.createdOn?.toISOString() || '',
            lastModified: item.properties.lastModified.toISOString(),
          };
          blobs.push({
            container: container,
            id: item.properties.etag,
            kind: item.kind,
            name: item.name,
            prefix: prefix,
            properties: properties,
            relativeName: getRelativeName(item.name),
            storageAccount: storageAccount,
            urlSafePath: getUrlSafePath(item.name),
          });
        } else {
          // Blob is a folder
          blobs.push({
            container: container,
            // Create a unique id for the folder so that it can be added to the store
            id: String(cyrb53(`${container}${item.name}`)),
            kind: item.kind,
            name: item.name,
            prefix: prefix,
            relativeName: getRelativeName(item.name),
            storageAccount: storageAccount,
            urlSafePath: getUrlSafePath(item.name),
          });
        }

        blobItem = await iter.next();
      }
      return blobs;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

const blobSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getBlobs.fulfilled, (state, { payload }) => {
      blobAdapter.upsertMany(state, payload);
      state.loading = 'idle';
    });
    // builder.addCase(getBlobs.rejected, (state, { payload }) => {
    //   // if (payload) {
    //   //   console.log(payload);
    //   // }
    // });
    builder.addCase(getBlobs.pending, (state) => {
      state.loading = 'pending';
    });
  },
  initialState: blobAdapter.getInitialState({
    loading: 'idle',
  }),
  name: 'blob',
  reducers: {},
});

export default blobSlice.reducer;

export const blobSelectors = blobAdapter.getSelectors((state: RootState) => state.blob);

// Selector to get blobs by prefix in a specific container in a storage account
export const blobSelectorByPrefix = createSelector(
  blobSelectors.selectAll,
  (state: RootState, prefix: string, storageAccount: string, container: string) => ({
    container,
    prefix,
    storageAccount,
  }),
  (blobs, { storageAccount, container, prefix }) =>
    blobs.filter(
      (blob) =>
        blob.storageAccount === storageAccount &&
        blob.container === container &&
        blob.prefix === prefix
    )
);

// Selector to get blobs by urlSafePath in a specific container in a storage account
export const blobSelectorByPath = createSelector(
  blobSelectors.selectAll,
  (state: RootState, path: string, storageAccount: string, container: string) => ({
    container,
    path,
    storageAccount,
  }),
  (blobs, { storageAccount, container, path }) =>
    blobs.find(
      (blob) =>
        blob.storageAccount === storageAccount &&
        blob.container === container &&
        blob.name === path &&
        blob.kind === 'blob'
    )
);
