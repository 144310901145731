import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import type { sasToken } from '../types/videostreaming.type';

interface sasArguments {
  accessToken: string;
  storageAccountName: string;
  containerName: string;
}

export const sasAPI = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: '/api' }),
  endpoints: (builder) => ({
    getSasToken: builder.query<sasToken, sasArguments>({
      query: ({ storageAccountName, containerName, accessToken }) => ({
        headers: {
          passthruauthorization: `${accessToken}`,
        },
        url: `sasToken?storageAccountName=${storageAccountName}&containerName=${containerName}`,
      }),
    }),
  }),
  reducerPath: 'sasAPI',
});

export const { useLazyGetSasTokenQuery } = sasAPI;
