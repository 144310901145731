import {
  FolderIcon,
  DocumentIcon,
  PhotoIcon,
  DocumentTextIcon,
  AtSymbolIcon,
  ArchiveBoxIcon,
} from '@heroicons/react/24/outline';
import type { ColumnDef } from '@tanstack/react-table';
import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  flexRender,
} from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';

import type { Blob } from '../../types/storageaccount.types';
import { humanFileSize, classNames, grabFileExtension } from '../../utils/utils';

type Props = {
  data: Blob[];
};

const columnHelper = createColumnHelper<Blob>();

function columnClassNames(id: string) {
  switch (id) {
    case 'kind':
      return 'w-10';
    case 'name':
      return 'whitespace-nowrap text-ellipsis overflow-hidden max-w-0';
    case 'contentLength':
      return 'w-24 min-w-max whitespace-nowrap';
    case 'lastModified':
      return 'w-52 min-w-max whitespace-nowrap';
    case 'contentType':
      return 'w-2 min-w-max whitespace-nowrap';
  }
  return id === 'kind' ? 'w-10' : '';
}

function BlobIcon({ blob }: { blob: Blob }) {
  switch (blob.kind) {
    case 'blob':
      const suffix = blob.name.split('.').pop()?.toLowerCase();
      switch (suffix) {
        case 'zip':
        case 'tar':
        case 'gz':
        case 'rar':
        case '7z':
          return <ArchiveBoxIcon className="mx-2 h-5 w-5 text-gray-400" />;
        case 'eml':
        case 'msg':
          return <AtSymbolIcon className="mx-2 h-5 w-5 text-gray-400" />;
        case 'pdf':
          return <DocumentTextIcon className="mx-2 h-5 w-5 text-gray-400" />;
        case 'png':
        case 'jpg':
        case 'jpeg':
        case 'gif':
          return <PhotoIcon className="mx-2 h-5 w-5 text-gray-400" />;
        default:
          return <DocumentIcon className="mx-2 h-5 w-5 text-gray-400" />;
      }
    default:
      return <FolderIcon className="mx-2 h-5 w-5 text-yellow-400" />;
  }
}

//for the Modified Date column, makes it more human readable than the UTC format
function convertDateString(date: string) {
  if (date != null || date != undefined) {
    const convertedDate = new Date(date);
    return convertedDate.toLocaleString();
  }
}

const columns: ColumnDef<Blob, any>[] = [
  columnHelper.accessor((row) => row.kind, {
    cell: (info) => <BlobIcon blob={info.row.original} />,
    footer: (info) => info.column.id,
    header: () => '',
    id: 'kind',
  }),
  columnHelper.accessor((row) => row.relativeName, {
    cell: (info) => info.getValue(),
    footer: (info) => info.column.id,
    header: () => 'Name',
    id: 'name',
  }),
  columnHelper.accessor((row) => row.properties?.contentLength, {
    cell: (info) => {
      const value = info.getValue();
      return value ? humanFileSize(value) : '';
    },
    footer: (info) => info.column.id,
    header: () => 'Size',
    id: 'contentLength',
  }),
  columnHelper.accessor((row) => row.properties?.lastModified, {
    cell: (info) => {
      return convertDateString(info.getValue());
    },
    footer: (info) => info.column.id,
    header: () => 'Modified Date',
    id: 'lastModified',
  }),
  columnHelper.accessor((row) => row.properties?.contentType, {
    cell: (info) => {
      const filename = info.row._valuesCache.name;
      return grabFileExtension(
        String(filename).toUpperCase(),
        String(info.row._valuesCache.lastModified),
        String(info.row._valuesCache.contentLength)
      );
    },
    footer: (info) => info.column.id,
    header: () => 'Type',
    id: 'contentType',
  }),
];

function Table({ data }: Props) {
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 40,
      },
    },
  });
  const navigate = useNavigate();

  const handleBlobClick = (blob: Blob) => {
    const navigateUrl = `/browse/${blob.storageAccount}/${blob.container}/${blob.urlSafePath}`;
    navigate(navigateUrl);
  };

  return (
    <div className="flex h-full flex-col">
      <div className="min-h-[30px] overflow-auto rounded-lg">
        <table className="w-full divide-y text-left">
          <thead className="sticky top-0 border-b bg-white dark:bg-slate-700">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th className={classNames(columnClassNames(header.id))} key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                className="cursor-pointer hover:bg-gray-50"
                key={row.id}
                onClick={() => handleBlobClick(row.original)}
              >
                {row.getVisibleCells().map((cell) => (
                  <td className={classNames(columnClassNames(cell.column.id))} key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="grow"></div>
      <div className="shrink-0 text-center">
        <button
          className="rounded border p-1 disabled:opacity-50"
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          {'<<'}
        </button>
        <button
          className="rounded border p-1 disabled:opacity-50"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {'<'}
        </button>
        <button
          className="rounded border p-1 disabled:opacity-50"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {'>'}
        </button>
        <button
          className="rounded border p-1 disabled:opacity-50"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          {'>>'}
        </button>
      </div>
    </div>
  );
}

export default Table;
