import { useIsAuthenticated } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';

interface Props {
  children: JSX.Element;
}

export function ProtectedRoute({ children }: Props) {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  if (!isAuthenticated) {
    navigate('/');
  }

  return children;
}
