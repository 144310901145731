import { useNavigate, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { selectedBlobIdSelector, clearBlobId } from '../../services/habSlice';
import { decodeBlobPath } from '../../utils/utils';

export default function BackButton({ disabled }) {
  const { storageAccountName, containerName, '*': blobPath } = useParams();
  const { prefix } = decodeBlobPath(blobPath ?? '');

  const navigate = useNavigate();

  const selectedBlobId = useAppSelector(selectedBlobIdSelector);

  const dispatch = useAppDispatch();

  const breadCrumbs = prefix.split('/').map((i) => encodeURIComponent(i) + '/');
  const handleClick = () => {
    const newPrefix = breadCrumbs.slice(0, -2).join('');
    navigate(`/browse/${storageAccountName}/${containerName}/${newPrefix}`);
    if (selectedBlobId) {
      dispatch(clearBlobId());
    }
  };

  return (
    <button
      className="rounded border p-1 disabled:opacity-50"
      onClick={handleClick}
      disabled={disabled}
    >
      {'← Back'}
    </button>
  );
}
