import type { QueryClient } from '@tanstack/react-query';
import { Allotment } from 'allotment';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';

import { ContainerSelector } from '../features/browse/ContainerSelector';
import { StorageAccountSelector } from '../features/browse/StorageAccountSelector';
import BlobList from '../features/containers/BlobList';
import DetailPane from '../features/detail/DetailPane';
import PreviewPane from '../features/detail/PreviewPane';
import { storageAccountsQuery, storageAccountContainersQuery } from '../queries/storageQueries';
import type { StorageAccount, Container } from '../types/storageaccount.types';

import 'allotment/dist/style.css';

interface LoaderParams {
  params: {
    storageAccountName?: string;
    containerName?: string;
    '*'?: string;
  };
}

export const loader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderParams) => {
    const { storageAccountName } = params;
    const storageAccounts = await queryClient.ensureQueryData(storageAccountsQuery());

    const containersQuery = storageAccountContainersQuery(params.storageAccountName ?? '');
    const storageAccountContainers: Container[] | undefined = storageAccountName
      ? await queryClient.ensureQueryData(containersQuery)
      : undefined;

    return { storageAccountContainers, storageAccounts };
  };

export default function Browse() {
  const navigate = useNavigate();

  const { storageAccountName, containerName } = useParams();

  const { storageAccounts } = useLoaderData() as { storageAccounts: StorageAccount[] };
  const { storageAccountContainers } = useLoaderData() as {
    storageAccountContainers: Container[] | undefined;
  };

  const handleStorageAccountChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    navigate(`/browse/${event.target.value}`);
  };

  const handleStorageContainerChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    navigate(`/browse/${storageAccountName}/${event.target.value}`);
  };

  return (
    <div className="h-full overflow-auto">
      <Allotment defaultSizes={[200, 100]} minSize={500}>
        <div className="h-full overflow-auto border-x-2">
          <div className="flex h-full flex-col overflow-auto border-2">
            <div className="ml-4 mt-3 flex flex-row p-2">
              <StorageAccountSelector
                storageAccounts={storageAccounts}
                selectedStorageAccount={storageAccountName}
                onStorageAccountChange={handleStorageAccountChange}
              />
              <ContainerSelector
                storageContainers={storageAccountContainers}
                selectedContainer={containerName}
                onContainerChange={handleStorageContainerChange}
                disabled={!storageAccountName}
              />
            </div>
            <BlobList />
          </div>
        </div>
        <div className="flex h-full flex-col overflow-auto border-2">
          <DetailPane />
          <PreviewPane />
        </div>
      </Allotment>
    </div>
  );
}
