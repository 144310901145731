import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { useDispatch, useSelector } from 'react-redux';

import { login, logout } from '../../services/authSlice';
import { Spinner } from '../spinner/Spinner';

function handleClick(dispatch, isAuthenticated, instance) {
  if (isAuthenticated) {
    dispatch(logout(instance));
  } else {
    dispatch(login(instance));
  }
}

export function SignInButton() {
  const dispatch = useDispatch();
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const authLoading = useSelector((state) => state.auth.loading);

  return (
    <div className="flex flex-row items-center">
      {authLoading === 'pending' ? (
        <div className="mr-2">
          <Spinner />
        </div>
      ) : null}
      <button
        className="mr-2 rounded-lg px-5 py-2.5 text-sm font-medium text-gray-800 hover:bg-gray-50 focus:outline-none focus:ring-4 focus:ring-gray-300"
        onClick={() => handleClick(dispatch, isAuthenticated, instance)}
      >
        {isAuthenticated ? 'Log out' : 'Log in'}
      </button>
    </div>
  );
}
